import { StaticImage } from 'gatsby-plugin-image'
import SearchBox from '@/components/shared/SearchBox'

const Navbar = () => {
  return (
    <div className="flex items-center px-5 py-6 tablet:space-x-12 desktop:space-x-20 xl:space-x-40">
      <StaticImage
        src="../../../../images/logo/navbar.png"
        alt="Vietnam Health Literacy Image"
        className="cursor-pointer max-w-[120px] md:max-w-[150px] desktop:max-w-[200px]"
        quality={100}
        width={668}
        height={232}
        formats={['auto', 'avif', 'webp']}
      />
      <SearchBox
        defaultValue=""
        parentClass="grow"
        className="py-2 pl-5 desktop:pl-[30px] pr-8 rounded-full h-[35px] w-full text-lg"
        type="full"
      />
    </div>
  )
}

export default Navbar
